import BaseService from "./base.service";

class MenuService {
    Menu(page = null, search = null) {
        let params = [];
        if(search) {
            params.push({'key':'q', 'value':search});
        }
        return BaseService.apiGetRequest('menu', page, params);
    }
	get(id) {
        return BaseService.apiGetRequest('menu/'+id);
    }
    delete(menu) {
        return BaseService.apiPostRequest('menu/delete/', menu);
    }    
    add(menu) {
        return BaseService.apiPostRequest('menu/add', menu);
    }
    update(id, menu) {
        return BaseService.apiPostRequest('menu/'+id, menu);
    }
    saveNestedSort(data) {
        return BaseService.apiPostRequest('menu/nested-sort', data);
    }
    search(page=null) {
        return BaseService.apiGetRequest('menu/search', page);
    }
    getmenupages() {
        return BaseService.apiGetRequest('menupages');
    }
    getparentpages() {
        return BaseService.apiGetRequest('parentpages');
    }
}
export default new MenuService();
