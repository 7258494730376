<template>
  <div>
    <div style="width:100%;display:flex;margin-bottom: 5px;">
      Drag-n-drop the Menu Items to sort and rearrange. Submenu is sortable too. Click <b>"Save Changes"</b> to update.
    <el-button
        type="primary"
        @click="saveChanges()"
        style="margin-left: auto; margin-right: 0; margin-bottom: 10px;"
    >Save Changes</el-button>
    </div>
    <vue-nestable
                  :value="menuElements"
                  @input="listUpdated"
                  cross-list
                  :max-depth="maxDepth"
                  :duplicate-item="duplicateItem"
                  group="cross"
                  :hooks="{
                    beforeMove: beforeMove,
                  }"
                  classProp="classProp"
    >
      <template v-slot="item">
        <vue-nestable-handle class="handle dark:o1-bg-gray-800 o1-flex io1-tems-center o1-justify-between o1-border o1-rounded-lg o1-outline-none o1-border-b o1-border-gray-200 dark:o1-border-gray-600 hide-cascade">
          <div class="o1-flex">
            <el-button
                v-if="hasChildren(item.item)"
                @click="toggleMenuChildrenCascade(item.item)"
                class="o1-appearance-none o1-cursor-pointer o1-fill-current hover:o1-text-primary o1-flex o1-px-3 o1-items-center focus:o1-outline-none"
            >
              <el-icon v-if="!isCascadeOpen(item.item)"><ArrowRightBold /></el-icon>
              <el-icon v-if="isCascadeOpen(item.item)"><ArrowDownBold /></el-icon>
            </el-button>
              <div
                  style="width: 50%; text-align: left; margin-left: 5px;"
                  class="o1-text-90 o1-font-bold o1-whitespace-nowrap o1-overflow-hidden o1-text-ellipsis"
                  :class="{ 'opacity-25': !item.enabled }"
              >
                {{ item.item.name }}
              </div>
              <div
                style="width: 50%; text-align: right"
                class="buttons md:o1-w-1/3 o1-flex o1-justify-end o1-content-center">
              <el-button
                  @click="performEdit(item.item)"
                  class="appearance-none cursor-pointer text-70 hover:text-primary mr-3"
              >
                Edit
              </el-button>
              <el-button
                  @click="performDelete(item.item)"
                  class="appearance-none cursor-pointer text-70 hover:text-primary mr-1"
              >
                Delete
              </el-button>
            </div>
          </div>
        </vue-nestable-handle>
      </template>
    </vue-nestable>
    <div style="width:100%;display:flex;margin-bottom: 5px;">
      <el-button
          type="primary"
          @click="saveChanges()"
          style="margin-left: auto; margin-right: 0; margin-top: 10px;"
      >Save Changes</el-button>
    </div>

  </div>
</template>

<script>
import {ElButton, ElIcon} from 'element-plus'
import { VueNestable, VueNestableHandle } from 'vue3-nestable';
import VueMultiselect from 'vue-multiselect'

export default {
  name: "MenuEditor",
  components: {
    ElButton, ElIcon,
    VueNestable,
    VueNestableHandle,
    VueMultiselect
  },
  props: {
    menuOptions: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      menuElements: [
        // {text: "CRM", name: "CRM", id: 3, enabled: true, showDuplicate: false, classProp:['hide-cascade'],
        //   children: [
        //     {text: "Leads", name: "Leads", id: 6, enabled: true, showDuplicate: false},
        //   ],
        // },
      ],
      maxDepth: 2,
      duplicateItem: false,
    }
  },
  mounted() {
    if(this.menuOptions && this.menuOptions.length > 0) {
      this.menuElements = this.menuOptions.map(function (m){
        return {text: m.name, name: m.name, id: m.id, enabled: true, showDuplicate: false, children: m.children, classProp:m.classProp}
      })
    }
  },
  methods: {
    performAddNew() {
      this.$emit("performAddNew");
    },
    performEdit(item){
      this.$emit("performEdit", item);
    },
    performDelete(item) {
      this.$emit("performDelete", item);
    },
    saveChanges() {
      this.$emit("performSaveChanges", this.menuElements);
    },
    // eslint-disable-next-line
    listUpdated(event) {
      this.menuElements = event
    },
    // eslint-disable-next-line
    editMenu(item){
    },
    // eslint-disable-next-line
    duplicateMenuItem(item){
    },
    // eslint-disable-next-line 
    removeMenu(item){
    },
    // eslint-disable-next-line
    changeValue(e) {
    },
    hasChildren(item) {
      return Array.isArray(item.children) && item.children.length;
    },
    toggleMenuChildrenCascade(item) {
      if (item.classProp.find(className => className === 'hide-cascade')) {
        item.classProp.splice(item.classProp.indexOf('hide-cascade'), 1);
      } else {
        item.classProp.push('hide-cascade');
      }
      // this.$emit('saveMenuLocalState', item);
    },
    isCascadeOpen(item) {
      return !item.classProp.find(className => className === 'hide-cascade');
    },
    // eslint-disable-next-line
    beforeMove({ dragItem, pathFrom, pathTo }) {
      // if (dragItem.children && dragItem.children.length) {
        return true;
      // }
      // return pathTo.length === 1;
    },
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss">
.o1-ml-4{margin-left:1rem}
.o1-flex{display:flex}
.o1-w-full{width:100%}
.o1-min-w-0{min-width:0}
.o1-shrink{flex-shrink:1}
.o1-cursor-pointer{cursor:pointer}
.o1-appearance-none{-webkit-appearance:none;-moz-appearance:none;appearance:none}
.o1-content-center{align-content:center}.o1-items-center{align-items:center}
.o1-justify-end{justify-content:flex-end}.o1-justify-between{justify-content:space-between}
.o1-overflow-hidden{overflow:hidden}.o1-text-ellipsis{text-overflow:ellipsis}.o1-whitespace-nowrap{white-space:nowrap}
.o1-rounded-lg{border-radius:.5rem}.o1-border{border-width:1px}.o1-border-b{border-bottom-width:1px}
.o1-border-gray-200{--tw-border-opacity:1;border-color:rgb(229 231 235/var(--tw-border-opacity))}
.o1-border-red-400{--tw-border-opacity:1;border-color:rgb(248 113 113/var(--tw-border-opacity))}
.o1-fill-current{fill:currentColor}.o1-px-3{padding-left:.75rem;padding-right:.75rem}

.o1-text-sm{font-size:.875rem;line-height:1.25rem}.o1-font-bold{font-weight:700}
.o1-opacity-25{opacity:.25}.focus\:o1-outline-none:focus,.o1-outline-none{outline:2px solid transparent;outline-offset:2px}
.o1-dark .dark\:o1-border-gray-600{--tw-border-opacity:1;border-color:rgb(75 85 99/var(--tw-border-opacity))}
.o1-dark .dark\:o1-bg-gray-800{--tw-bg-opacity:1;background-color:rgb(31 41 55/var(--tw-bg-opacity))}
@media (min-width:768px){.md\:o1-w-1\/3{width:33.333333%}}


.nestable {
  position: relative;
}

.nestable-rtl {
  direction: rtl;
}

.nestable .nestable-list {
  margin: 0;
  padding: 0 0 0 40px;
  list-style-type: none;
}

.nestable-rtl .nestable-list {
  padding: 0 40px 0 0;
}

.nestable>.nestable-list {
  padding: 0;
}

.nestable-item,
.nestable-item-copy {
  margin: 10px 0 0;
}

.nestable-item:first-child,
.nestable-item-copy:first-child {
  margin-top: 0;
}

.nestable-item .nestable-list,
.nestable-item-copy .nestable-list {
  //margin-top: 10px;
}

.nestable-item {
  position: relative;
}

.nestable-item.is-dragging .nestable-list {
  pointer-events: none;
}

.nestable-item.is-dragging * {
  opacity: 0;
  filter: alpha(opacity=0);
}

.nestable-item.is-dragging:before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(106, 127, 233, 0.274);
  border: 1px dashed rgb(73, 100, 241);
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

.nestable-drag-layer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  pointer-events: none;
}

.nestable-rtl .nestable-drag-layer {
  left: auto;
  right: 0;
}

.nestable-drag-layer>.nestable-list {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  background-color: rgba(106, 127, 233, 0.274);
}

.nestable-rtl .nestable-drag-layer>.nestable-list {
  padding: 0;
}

.nestable [draggable="true"] {
  cursor: move;
}

.nestable-handle {
  display: inline;
}

.menu-builder {
  .v-popover,
  .v-popover > * > span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.justify-end {
  justify-content: flex-end;
}
.opacity-25 {
  opacity: 0.25;
}
.nestable-item {
  margin: 0px;
}
.nestable-item-content{
  border: solid 2px #dce4eb;
  background-color: #f8fafc;
  padding: 0 2px;
  margin: 1px;
}
.hide-cascade > ol {
  display: none;
}
</style>
